<template>
  <div class="home-class">
    <div class="power-station">
      <!-- 头部部分 -->
      <div ref="search_d">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="电站">
            <el-select v-model="searchForm.powerStationCode" placeholder="请选择电站" clearable>
              <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName"
                :value="item.powerStationCode">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="逆变器类型">
            <el-select v-model="searchForm.value" placeholder="请选择" clearable>
              <el-option v-for="item in searchForm.options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker placeholder="请选择日期" v-model="searchForm.date" style="width: 100%;" clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <!-- <el-button type="primary" @click="resert">重置</el-button> -->
            <el-button type="primary" @click="search">查询</el-button>
            <exportBtn :params="expParams"/>
          </el-form-item>
        </el-form>
      </div>

      <!-- 表格部分 -->
      <div>
        <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" height="74vh">
          <el-table-column label="位置名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerStationName }}</template>
          </el-table-column>
          <el-table-column label="逆变器名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.deviceName }}</template>
          </el-table-column>
          <el-table-column label="额定功率(kw)" align="center" show-overflow-tooltip>
            <template slot-scope="scope">-</template>
          </el-table-column>
          <el-table-column label="峰值功率(kw)" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.currEfficiency / 1000 }}</template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
            class="pagination-style"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="(labelType == 'add' ? '新增' : '编辑')" :visible.sync="showDialog" width="800px">
      <el-form style="form-class" ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">

          <el-col :span="12">
            <el-form-item label="：" prop="siteName">
              <el-input v-model="form.id" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="电站名称：" prop="siteName">
              <el-input v-model="form.powerStationName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="创建时间：" prop="siteName">
              <el-input v-model="form.createDt" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="位置：" prop="siteName">
              <el-input v-model="form.location" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="额定功率：" prop="siteName">
              <el-input v-model="form.power" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="峰值功率：" prop="siteName">
              <el-input v-model="form.maxPower" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期：" prop="siteName">
              <el-input v-model="form.dayTime" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编号：" prop="siteName">
              <el-input v-model="form.deviceNo" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备SN（设备唯一编码）：" prop="siteName">
              <el-input v-model="form.deviceSn" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称：" prop="siteName">
              <el-input v-model="form.deviceName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设备类型名称：" prop="siteName">
              <el-input v-model="form.deviceTypeName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import exportBtn from '@/components/exportBtn.vue'
export default {
  data() {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {},
      searchForm: {},
      powerStationList: [],// 电站列表
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 290, //表格高度
      headerHeight: 0,
      expParams:{
        url:'',
        fileName:''
      },
    };
  },
  components:{
    exportBtn
  },

  created() {
    this.getList()
    this.getSelectList()
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 190;
    },

    search() {
      // this.searchForm
      this.queryInfo.condition = this.searchForm
      this.getList()
    },

    //获取数据
    getList() {
      var that = this;
      that.$http.post("/powerStationDeviceSummary/queryPageByStation", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    getSelectList() {
      // 电站列表
      this.$http
        .post("powerStation/list", { currPage: 1, pageSize: 100 })
        .then((res) => {
          if (res.data.code == 200) {
            this.powerStationList = res.data.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });

      // // 设备类型
      // this.$http
      //   .post("deviceType/queryList", { currPage: 1, pageSize: 100 })
      //   .then((res) => {
      //     if (res.data.code == 200) {
      //       this.deviceTypeList = res.data.data;
      //       console.log(this.deviceTypeList,407);
      //     } else {
      //       this.$message.error(res.data.message);
      //     }
      //   });
    },

    // 保存
    handleSave() {
      var that = this;
      that.$http.post("/powerStationNbqPowerTotal/save", that.form).then(function (response) {
        if (response.data.code == 200) {
          that.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
          that.showDialog = false
          that.getList()
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      }).catch(function (error) {


      })


    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = 'edit'
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/powerStationNbqPowerTotal/delete", { "id": id }).then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }

        });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {}
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>


<style scoped lang="scss">
.home-class {
  background: #223f6c;
  padding: 10px;
  height: 100%;

  .power-station {
    height: 100%;
    background: #2d5981;
    padding: 8px;

    .form-class {
      ::v-deep .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
      }

      ::v-deep .el-input__inner {
        border-radius: 5px;
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
    }

    ::v-deep .el-input-group__append {
      background: #409EFF;
      color: #fff;
    }

    /deep/ .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }

    ::v-deep .el-input__inner {
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
      text-align: left;
      line-height: normal;
    }

    ::v-deep .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }

    ::v-deep .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table__body-wrapper {
      background: #2d5981;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
      background-color: #223f6c !important;
    }

    ::v-deep .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    ::v-deep .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }

    ::v-deep .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }

    ::v-deep .el-pager li {
      background: none;
      color: #f2f2f2;
    }

    ::v-deep .el-icon {
      color: #fff;
    }

    ::v-deep .el-pagination .btn-prev {
      background: none;
    }

    ::v-deep .el-pagination .btn-next {
      background: none;
    }

    ::v-deep .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }

    ::v-deep .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }

    ::v-deep .el-icon-close:before {
      color: #333;
    }

    ::v-deep .el-upload {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
}
</style>